export const WORDS = [
  'smrek',
  'trvať',
  'činiť',
  'džudo',
  'vôkol',
  'perla',
  'miera',
  'sekta',
  'nikto',
  'hrbáč',
  'kameň',
  'vstup',
  'viera',
  'kulma',
  'spona',
  'bydlo',
  'siliť',
  'vyšný',
  'sádka',
  'zhniť',
  'tkaný',
  'kúriť',
  'pudlo',
  'luxus',
  'rokľa',
  'čárda',
  'kocúr',
  'tiger',
  'mohér',
  'dôraz',
  'gánok',
  'vláda',
  'rafať',
  'súčin',
  'pokoj',
  'mlecí',
  'barok',
  'maród',
  'radno',
  'kozák',
  'panic',
  'orloj',
  'zvesť',
  'ústrk',
  'ôstie',
  'ťapka',
  'rapík',
  'učivo',
  'blkom',
  'zákos',
  'vĺčok',
  'klásť',
  'mágia',
  'nadeň',
  'výzva',
  'crawl',
  'vážiť',
  'šíriť',
  'bagún',
  'brvno',
  'fagot',
  'ostrý',
  'nosáň',
  'teplý',
  'tehla',
  'rifle',
  'kakať',
  'motor',
  'beľmo',
  'výkon',
  'hltan',
  'vrkoč',
  'tramp',
  'mrkať',
  'rúbať',
  'pykať',
  'buxus',
  'baliť',
  'záliv',
  'mudrc',
  'úplet',
  'čarať',
  'balón',
  'bosky',
  'chaos',
  'výbor',
  'zážih',
  'vpust',
  'vetva',
  'hútať',
  'hôliť',
  'gúľať',
  'pohyb',
  'angín',
  'vŕšiť',
  'hatiť',
  'čujný',
  'odlev',
  'díler',
  'šudiť',
  'súhrn',
  'borba',
  'vtedy',
  'kočík',
  'sadza',
  'hodno',
  'flirt',
  'ihrať',
  'grant',
  'hovor',
  'kniha',
  'ušatý',
  'maras',
  'bývať',
  'mazať',
  'gesto',
  'baret',
  'šifón',
  'pauza',
  'hánka',
  'neter',
  'párny',
  'sezam',
  'lapač',
  'totiž',
  'senáž',
  'poter',
  'kýbeľ',
  'ručný',
  'cárok',
  'siaha',
  'plniť',
  'rieka',
  'vjazd',
  'kapsa',
  'pocta',
  'aorta',
  'čajka',
  'epika',
  'práve',
  'zľava',
  'rodák',
  'uliať',
  'malér',
  'lekár',
  'ušiak',
  'úroda',
  'obuch',
  'porto',
  'zrána',
  'trený',
  'vdych',
  'gáfor',
  'lagan',
  'humus',
  'doska',
  'kríza',
  'kohút',
  'bujný',
  'mulat',
  'škrek',
  'skalp',
  'lokša',
  'klbko',
  'drvič',
  'syčať',
  'jatka',
  'švung',
  'cveng',
  'behom',
  'vývar',
  'začuť',
  'pŕška',
  'buklé',
  'rondo',
  'mozog',
  'brdce',
  'gunár',
  'záhon',
  'žaluď',
  'porub',
  'kutať',
  'plebs',
  'zadný',
  'trópy',
  'užitý',
  'údený',
  'treba',
  'mláďa',
  'ornát',
  'lobby',
  'skoro',
  'rapeľ',
  'šípiť',
  'decht',
  'lezec',
  'niesť',
  'úpieť',
  'kadet',
  'záver',
  'súžiť',
  'bájny',
  'jagot',
  'bieda',
  'tabla',
  'prvák',
  'kaliť',
  'zízať',
  'letka',
  'kavka',
  'počas',
  'živio',
  'lačný',
  'kalný',
  'zúriť',
  'vírus',
  'búšiť',
  'dieťa',
  'razia',
  'živec',
  'vedec',
  'čelný',
  'šafár',
  'hi-hi',
  'ofset',
  'vonku',
  'výpek',
  'tarok',
  'višňa',
  'kapať',
  'sejba',
  'fauna',
  'konár',
  'lanár',
  'séria',
  'sépia',
  'rikša',
  'búrka',
  'zábal',
  'svrab',
  'ústiť',
  'hymna',
  'ťahať',
  'vikár',
  'živný',
  'chróm',
  'kosba',
  'dobrý',
  'bubák',
  'kyjak',
  'nápev',
  'pekáč',
  'kaput',
  'odvar',
  'spred',
  'počuť',
  'pitva',
  'fókus',
  'odbyt',
  'tucet',
  'mocný',
  'mamka',
  'krčiť',
  'večný',
  'pošiť',
  'dokár',
  'mladý',
  'nútiť',
  'tatra',
  'smola',
  'mykať',
  'štart',
  'trčať',
  'hrboľ',
  'úhona',
  'váhať',
  'víťaz',
  'násyp',
  'spací',
  'čibuk',
  'ofina',
  'sršeň',
  'motto',
  'šanca',
  'káčer',
  'spust',
  'vokáň',
  'tátoš',
  'rolák',
  'dekan',
  'stroj',
  'frank',
  'frmol',
  'palec',
  'bodka',
  'máčať',
  'čemer',
  'pevec',
  'náčrt',
  'tajný',
  'žízeň',
  'lúpež',
  'pušný',
  'fešák',
  'šelak',
  'dávka',
  'empír',
  'elita',
  'zubáľ',
  'rabat',
  'rezák',
  'hodne',
  'modrý',
  'siena',
  'pobyt',
  'popol',
  'rovný',
  'korzo',
  'vývoj',
  'spiež',
  'razič',
  'truľo',
  'samba',
  'okruh',
  'šofér',
  'súcit',
  'cnosť',
  'lámač',
  'bunka',
  'výjav',
  'šúľať',
  'lajno',
  'medzi',
  'jazva',
  'fréza',
  'slepý',
  'výdoj',
  'bralo',
  'fúrik',
  'hegať',
  'čmára',
  'pasca',
  'botel',
  'soboľ',
  'tŕžiť',
  'sušeň',
  'krása',
  'výpon',
  'pleso',
  'smelý',
  'ideál',
  'hudec',
  'fárať',
  'epeda',
  'horký',
  'prvok',
  'súťaž',
  'žalár',
  'kabát',
  'šípka',
  'obrys',
  'žravý',
  'vzdor',
  'sérum',
  'lipeň',
  'trasa',
  'žíriť',
  'rahno',
  'video',
  'razom',
  'posun',
  'ligot',
  'dlháň',
  'rubáš',
  'druhý',
  'chabý',
  'cirok',
  'trúba',
  'skryť',
  'tlapa',
  'voľný',
  'polos',
  'odtok',
  'cezeň',
  'nikam',
  'nádor',
  'rumba',
  'orech',
  'manna',
  'čo-to',
  'sýtiť',
  'možný',
  'gumák',
  'korec',
  'ortuť',
  'vrtký',
  'sauna',
  'čučať',
  'vedro',
  'keper',
  'cenný',
  'činel',
  'vrana',
  'príma',
  'chmat',
  'sluha',
  'bedár',
  'repka',
  'dália',
  'zátka',
  'ponor',
  'drdol',
  'zúžiť',
  'trpký',
  'soliť',
  'chrup',
  'nugat',
  'kázať',
  'nápoj',
  'pošta',
  'véčko',
  'istiť',
  'vloha',
  'dožiť',
  'odpad',
  'žabec',
  'pľušť',
  'črtať',
  'dolka',
  'mrviť',
  'ktosi',
  'kreol',
  'ruský',
  'rátať',
  'rugby',
  'komár',
  'kárny',
  'plnič',
  'kanec',
  'blana',
  'likér',
  'časný',
  'pôjde',
  'svist',
  'netto',
  'silák',
  'záťah',
  'tržba',
  'priek',
  'cirka',
  'zošit',
  'čížik',
  'blond',
  'kukuk',
  'stlať',
  'ťažeň',
  'štiap',
  'počet',
  'tokár',
  'mokrý',
  'púder',
  'škuta',
  'hrýzť',
  'čiara',
  'žemľa',
  'šťava',
  'pirát',
  'kibic',
  'osúch',
  'kvóta',
  'korba',
  'nažať',
  'xerox',
  'hýriť',
  'kocka',
  'fikus',
  'vnohy',
  'kraul',
  'strmý',
  'norma',
  'hanba',
  'vydra',
  'klika',
  'facka',
  'syseľ',
  'spola',
  'výrub',
  'súbeh',
  'ikona',
  'pažiť',
  'nábeh',
  'kúpač',
  'kozľa',
  'povoz',
  'rakva',
  'balič',
  'udica',
  'trysk',
  'hrúza',
  'vďaka',
  'jelša',
  'dobyť',
  'špunt',
  'címer',
  'titán',
  'banka',
  'šikmý',
  'hydra',
  'lyska',
  'jedoš',
  'paľba',
  'kopiť',
  'nanič',
  'pupeň',
  'vezír',
  'dravý',
  'grapa',
  'nymfa',
  'tamže',
  'xenón',
  'nerez',
  'požať',
  'hopsa',
  'model',
  'nátoň',
  'nádej',
  'stvol',
  'bárka',
  'kotúľ',
  'frnda',
  'drkot',
  'ihaha',
  'rehot',
  'čpieť',
  'behák',
  'vízum',
  'fotka',
  'lomoz',
  'torba',
  'zavyť',
  'výpis',
  'mazna',
  'lákať',
  'výber',
  'fikot',
  'macko',
  'orkán',
  'šliam',
  'zabiť',
  'proso',
  'obtok',
  'pasák',
  'výťah',
  'tlstý',
  'znieť',
  'blesk',
  'packa',
  'frfot',
  'návej',
  'osada',
  'onaký',
  'lupeň',
  'algol',
  'ladný',
  'sovok',
  'sorta',
  'bodný',
  'vitaj',
  'nikel',
  'pekný',
  'prasa',
  'horec',
  'pleva',
  'sadba',
  'radiť',
  'tento',
  'trias',
  'vzduť',
  'zásek',
  'dudok',
  'vrásť',
  'agáva',
  'sotva',
  'krpec',
  'sedem',
  'ťahák',
  'paloš',
  'vánok',
  'kvark',
  'barel',
  'album',
  'výrok',
  'počin',
  'haliť',
  'jasný',
  'ústup',
  'sedlo',
  'zhryz',
  'renta',
  'jemný',
  'vybiť',
  'hĺbič',
  'sokol',
  'dopis',
  'palma',
  'lebeň',
  'pasát',
  'balík',
  'keťas',
  'domov',
  'vzťah',
  'potom',
  'major',
  'kotva',
  'lopta',
  'táčky',
  'tavič',
  'ostať',
  'sprej',
  'húkať',
  'rítus',
  'ďugať',
  'junač',
  'mužný',
  'smrad',
  'pasáž',
  'požuť',
  'balet',
  'ruina',
  'vzlet',
  'kotúč',
  'vtáča',
  'západ',
  'hučať',
  'bowle',
  'pylón',
  'bežec',
  'odsať',
  'salón',
  'kecať',
  'linka',
  'žabča',
  'ingot',
  'celiť',
  'sústo',
  'bobor',
  'sféra',
  'únava',
  'fólia',
  'požiť',
  'zárez',
  'hĺbiť',
  'kotný',
  'próba',
  'otrok',
  'hádať',
  'loziť',
  'mucha',
  'desný',
  'foyer',
  'ampér',
  'zákal',
  'géčka',
  'vonný',
  'tanín',
  'točiť',
  'jutro',
  'zívať',
  'striž',
  'egreš',
  'hojiť',
  'šatiť',
  'dubák',
  'odsek',
  'okraj',
  'úloha',
  'fuzáč',
  'akord',
  'doňho',
  'troje',
  'švola',
  'sečka',
  'nosič',
  'tieto',
  'nemoc',
  'potok',
  'tlkot',
  'burza',
  'sokel',
  'plesk',
  'komín',
  'flóra',
  'slych',
  'džgať',
  'zobať',
  'strek',
  'šmuha',
  'medza',
  'kvaka',
  'solux',
  'adept',
  'dusík',
  'sprať',
  'výraz',
  'kazak',
  'odvoz',
  'lápis',
  'puška',
  'kanón',
  'sýtič',
  'hrkot',
  'hroch',
  'moped',
  'tablo',
  'fúľať',
  'norka',
  'znelý',
  'čeľaď',
  'píliť',
  'kopec',
  'híkať',
  'kacír',
  'sumec',
  'halda',
  'dural',
  'obruč',
  'diván',
  'lyzol',
  'láska',
  'chrám',
  'kúria',
  'točňa',
  'mrena',
  'fotiť',
  'dakto',
  'účasť',
  'alebo',
  'maslo',
  'korok',
  'zápis',
  'klzký',
  'cícer',
  'achát',
  'pôvab',
  'tuner',
  'šnúra',
  'vajda',
  'roveň',
  'agent',
  'fufna',
  'misie',
  'súhra',
  'osoba',
  'pšeno',
  'bujón',
  'žičiť',
  'hašiš',
  'záčin',
  'zamak',
  'makať',
  'jódny',
  'lalok',
  'pitný',
  'cecok',
  'dávno',
  'mútiť',
  'kdeby',
  'rováš',
  'kálať',
  'kamža',
  'skunk',
  'kliať',
  'mátať',
  'kasať',
  'čušať',
  'išpán',
  'fušer',
  'marža',
  'čižma',
  'šumný',
  'zafír',
  'kasár',
  'enzým',
  'podľa',
  'metal',
  'pudiť',
  'sosna',
  'uznať',
  'trhák',
  'mečať',
  'šľapa',
  'šuter',
  'výplň',
  'kríže',
  'drsný',
  'šupák',
  'drieť',
  'odbor',
  'clona',
  'koňak',
  'kutáč',
  'gusto',
  'macať',
  'odiať',
  'tavný',
  'koľký',
  'zloba',
  'tesák',
  'suchý',
  'lizák',
  'kapec',
  'necht',
  'banjo',
  'metán',
  'koňmo',
  'zeman',
  'osivo',
  'tácňa',
  'sadra',
  'vábec',
  'junec',
  'valal',
  'vyhňa',
  'mrhať',
  'frčať',
  'perie',
  'meter',
  'jalec',
  'fusak',
  'mnoho',
  'osebe',
  'karas',
  'váľať',
  'chrst',
  'tužka',
  'sykot',
  'opiát',
  'úľava',
  'skyva',
  'zopár',
  'zubor',
  'gazda',
  'ledač',
  'debna',
  'kečup',
  'organ',
  'berla',
  'drgať',
  'fjord',
  'krčah',
  'veriť',
  'súcno',
  'maser',
  'síkať',
  'albín',
  'víriť',
  'sklon',
  'čakan',
  'metro',
  'dolný',
  'telúr',
  'výrez',
  'húžva',
  'tlama',
  'útvar',
  'odliv',
  'rázny',
  'stály',
  'sluch',
  'mrmot',
  'anóda',
  'črevo',
  'tajiť',
  'titan',
  'stisk',
  'baviť',
  'chudý',
  'tenúť',
  'dávny',
  'rígeľ',
  'závet',
  'mrieť',
  'okatý',
  'zlatý',
  'match',
  'pôrod',
  'náčuv',
  'výter',
  'torta',
  'jarok',
  'kapia',
  'uhlík',
  'aróma',
  'podlý',
  'kryha',
  'mútny',
  'besný',
  'kepeň',
  'stoka',
  'ňadro',
  'šimeľ',
  'panel',
  'žírny',
  'nylon',
  'hybný',
  'datív',
  'majer',
  'bafať',
  'filip',
  'pravý',
  'hýbať',
  'krpáň',
  'magma',
  'výmaz',
  'pudel',
  'strýc',
  'parta',
  'fukso',
  'úplný',
  'výkup',
  'prací',
  'dupľa',
  'poviť',
  'lúpiť',
  'vtĺcť',
  'limit',
  'dosah',
  'čadič',
  'pokus',
  'vesna',
  'cepík',
  'hrant',
  'beťár',
  'fuňať',
  'vodík',
  'rúško',
  'nízky',
  'taška',
  'beluš',
  'nákup',
  'vydať',
  'hárok',
  'vodca',
  'rovno',
  'štvať',
  'blúza',
  'bucko',
  'lupič',
  'oprať',
  'blcha',
  'kurín',
  'nečas',
  'dozor',
  'kačín',
  'priča',
  'losos',
  'klíma',
  'desať',
  'hárem',
  'hapať',
  'bájka',
  'rampa',
  'čistý',
  'sánka',
  'vnúča',
  'žáner',
  'tetka',
  'diéty',
  'trova',
  'bdieť',
  'toľko',
  'skóre',
  'bedač',
  'hrbiť',
  'pádlo',
  'senát',
  'sobáš',
  'známy',
  'mnohý',
  'párty',
  'zápal',
  'cedák',
  'trhač',
  'blkot',
  'pukot',
  'lelek',
  'flauš',
  'kýpeť',
  'bronz',
  'pikle',
  'reuma',
  'škoda',
  'gulag',
  'retuš',
  'čapík',
  'výfuk',
  'koľaj',
  'joule',
  'pôvod',
  'úklon',
  'úžina',
  'súboj',
  'kosák',
  'boxer',
  'kukať',
  'stádo',
  'nosný',
  'drtiť',
  'stena',
  'dohad',
  'pošva',
  'tenor',
  'oblek',
  'salto',
  'zboka',
  'ručať',
  'pedál',
  'nažka',
  'ťapša',
  'kočiš',
  'vresk',
  'decko',
  'štóla',
  'naraz',
  'jazyk',
  'vráta',
  'jogín',
  'dolár',
  'razba',
  'južan',
  'živel',
  'badať',
  'kotol',
  'dučať',
  'náboj',
  'nosiť',
  'rozum',
  'torzo',
  'zájsť',
  'nájom',
  'nálož',
  'obdiv',
  'byvol',
  'pojať',
  'skruž',
  'front',
  'húpať',
  'spraš',
  'debut',
  'slaný',
  'vecný',
  'happy',
  'mékať',
  'ceniť',
  'popud',
  'počty',
  'panna',
  'voška',
  'satan',
  'viesť',
  'pieta',
  'týfus',
  'bizam',
  'nadať',
  'sujet',
  'krivý',
  'fajta',
  'tonúť',
  'relax',
  'masív',
  'naduť',
  'začať',
  'hajaj',
  'výtlk',
  'sklad',
  'takže',
  'šúpať',
  'doraz',
  'pupok',
  'fixný',
  'sudba',
  'liace',
  'šibal',
  'sirup',
  'uspať',
  'tržný',
  'vodné',
  'útlak',
  'suita',
  'zoťať',
  'otras',
  'výsek',
  'ľalia',
  'planý',
  'perón',
  'zákaz',
  'busta',
  'causa',
  'pekár',
  'surma',
  'tapír',
  'sadiť',
  'nárez',
  'teľný',
  'melón',
  'slang',
  'henry',
  'nadir',
  'újesť',
  'zažiť',
  'bičík',
  'taviť',
  'časom',
  'parte',
  'bočný',
  'šírka',
  'preso',
  'dusný',
  'hmkať',
  'seter',
  'pasia',
  'líder',
  'grand',
  'včela',
  'hukot',
  'sodík',
  'mlčať',
  'trdlo',
  'popri',
  'šerif',
  'šiška',
  'nanuk',
  'cifra',
  'tučný',
  'hruda',
  'tatko',
  'rúcať',
  'pádny',
  'látka',
  'rákoš',
  'hľuza',
  'hlien',
  'fiala',
  'fučka',
  'lesák',
  'hrvoľ',
  'crkať',
  'naňho',
  'žĺtok',
  'krytý',
  'zliať',
  'mýlka',
  'lomka',
  'plavý',
  'fičať',
  'Pýtia',
  'sklár',
  'tesný',
  'fenig',
  'ambit',
  'slzný',
  'sopeľ',
  'arzén',
  'vodák',
  'vrhač',
  'jasot',
  'bahor',
  'okovy',
  'doryť',
  'dobro',
  'slnce',
  'vrása',
  'čajsi',
  'zosun',
  'vrece',
  'kahan',
  'blčať',
  'skrat',
  'týmus',
  'uhnúť',
  'hustý',
  'cítiť',
  'poník',
  'tkací',
  'dovoz',
  'sláva',
  'forma',
  'poliš',
  'pečeň',
  'kolmý',
  'babka',
  'triko',
  'holub',
  'snaha',
  'kdesi',
  'sadlo',
  'omelo',
  'pučať',
  'orgán',
  'skaut',
  'idiot',
  'horár',
  'šepot',
  'tunel',
  'lúpať',
  'vítať',
  'šperk',
  'habit',
  'bledý',
  'mrkva',
  'nikdy',
  'bôčik',
  'žblnk',
  'drozd',
  'sivko',
  'citát',
  'efekt',
  'pahýľ',
  'akoby',
  'plášť',
  'boršč',
  'vízia',
  'ubrať',
  'extra',
  'vágny',
  'týrať',
  'sypať',
  'vzlyk',
  'gnóma',
  'grunt',
  'lurex',
  'cimra',
  'nácia',
  'civil',
  'riava',
  'email',
  'grogy',
  'odpis',
  'hurka',
  'záves',
  'trmať',
  'načim',
  'otvor',
  'avízo',
  'došiť',
  'maják',
  'toľký',
  'ledva',
  'kroch',
  'mlieč',
  'cesta',
  'japan',
  'zenit',
  'rámec',
  'noriť',
  'odrať',
  'línia',
  'marón',
  'odnož',
  'dezén',
  'erika',
  'forte',
  'dráma',
  'ľakať',
  'mrzký',
  'ďasno',
  'divák',
  'rafáč',
  'báčik',
  'trapy',
  'pyšný',
  'líšťa',
  'jeleň',
  'síran',
  'hrach',
  'tempo',
  'topiť',
  'kaška',
  'kvôli',
  'radža',
  'tušiť',
  'vyduť',
  'pýtať',
  'zlosť',
  'pária',
  'rypák',
  'zvedy',
  'zvrat',
  'šafeľ',
  'bodák',
  'čudný',
  'ťažný',
  'román',
  'viezť',
  'lýtko',
  'kojot',
  'dlžný',
  'ohľad',
  'gágať',
  'vozeň',
  'šaman',
  'pi-pi',
  'etuda',
  'odvod',
  'námet',
  'pisár',
  'kúpiť',
  'vzísť',
  'krédo',
  'výlet',
  'kopáč',
  'radlo',
  'popuk',
  'aktív',
  'uhlie',
  'olovo',
  'jupka',
  'biely',
  'zrazu',
  'výdaj',
  'alibi',
  'lámka',
  'sporý',
  'medik',
  'polka',
  'mumák',
  'sukňa',
  'tonáž',
  'žaket',
  'klišé',
  'bacať',
  'trhať',
  'detný',
  'bidlo',
  'synak',
  'umelý',
  'jahňa',
  'krvák',
  'žetón',
  'vzadu',
  'vŕtač',
  'zaraz',
  'garda',
  'koľko',
  'otava',
  'vinný',
  'kŕmny',
  'break',
  'nižný',
  'srnča',
  'vidno',
  'rôzny',
  'objav',
  'kľavý',
  'spoza',
  'chyža',
  'splav',
  'český',
  'vzdať',
  'ruman',
  'fŕkať',
  'trpák',
  'fľask',
  'radný',
  'kôlňa',
  'posuv',
  'čeliť',
  'plisé',
  'katar',
  'unfér',
  'tiecť',
  'istič',
  'pokým',
  'liana',
  'disko',
  'držba',
  'vizáž',
  'hliva',
  'zmier',
  'odboj',
  'vodný',
  'volať',
  'pôdoj',
  'revue',
  'fórum',
  'glgať',
  'jáger',
  'ľudák',
  'zánik',
  'árešt',
  'naháč',
  'dedič',
  'smejo',
  'tesár',
  'anály',
  'dláto',
  'pažba',
  'ulica',
  'kĺzať',
  'kyprý',
  'stret',
  'tupec',
  'oznam',
  'súčet',
  'vrhať',
  'rohož',
  'obísť',
  'hrubý',
  'blbec',
  'nultý',
  'írsky',
  'taxík',
  'super',
  'dieža',
  'tavba',
  'mĺkvy',
  'kubík',
  'bytie',
  'hoboj',
  'obviť',
  'zhora',
  'zjesť',
  'kšeft',
  'záťaž',
  'topor',
  'tuším',
  'búrať',
  'rubač',
  'hobra',
  'zadať',
  'jodid',
  'dupať',
  'pôjsť',
  'ťažba',
  'déčko',
  'náuka',
  'tepna',
  'zočiť',
  'sraľo',
  'šamot',
  'etapa',
  'putňa',
  'frčka',
  'talár',
  'liter',
  'hrôza',
  'rezok',
  'tiráž',
  'priam',
  'buksa',
  'afekt',
  'liezť',
  'žervé',
  'síriť',
  'prior',
  'širák',
  'jeseň',
  'závan',
  'sused',
  'rezať',
  'zavše',
  'odber',
  'kujon',
  'sitár',
  'smršť',
  'náplň',
  'chyba',
  'dinár',
  'rínok',
  'kriak',
  'žumpa',
  'kolok',
  'kefír',
  'stóra',
  'šidlo',
  'vyryť',
  'stuha',
  'voziť',
  'kanál',
  'kynúť',
  'výsev',
  'hasák',
  'šička',
  'bŕkať',
  'mitra',
  'pagáč',
  'trkot',
  'ťahúň',
  'kulak',
  'bufet',
  'okolo',
  'šaľba',
  'všade',
  'devón',
  'frkan',
  'režim',
  'voňať',
  'vážny',
  'kapor',
  'život',
  'hybký',
  'menza',
  'batoh',
  'tikať',
  'mních',
  'nános',
  'slabý',
  'volán',
  'peklo',
  'pijan',
  'povel',
  'vliať',
  'šrafa',
  'káder',
  'lekno',
  'leták',
  'sídlo',
  'mazut',
  'výpad',
  'česák',
  'každý',
  'lavór',
  'jedľa',
  'rezeň',
  'darmo',
  'debet',
  'pláca',
  'boriť',
  'celok',
  'ocino',
  'tilda',
  'brada',
  'záder',
  'salaš',
  'písať',
  'kamoš',
  'lapaj',
  'rébus',
  'pariť',
  'lacný',
  'súvis',
  'otlak',
  'fajka',
  'traky',
  'nákyp',
  'nitár',
  'bedľa',
  'vábny',
  'sivoň',
  'pľúca',
  'drviť',
  'kozub',
  'súpis',
  'tákať',
  'odťať',
  'pamuk',
  'raniť',
  'piroh',
  'zrieť',
  'rodič',
  'guláš',
  'mlčky',
  'oblúk',
  'béčka',
  'detto',
  'butik',
  'kokos',
  'žmurk',
  'výkyv',
  'závoj',
  'čudák',
  'he-he',
  'poňho',
  'tábor',
  'čuvač',
  'tuzex',
  'niečo',
  'princ',
  'tŕpka',
  'fuška',
  'návrh',
  'vdova',
  'kosec',
  'mlsný',
  'kŕmič',
  'štich',
  'vonka',
  'miesť',
  'splna',
  'telex',
  'obťať',
  'pohov',
  'šarža',
  'kiosk',
  'korán',
  'brzda',
  'hriva',
  'pašie',
  'mýtus',
  'chmeľ',
  'kaluž',
  'lepší',
  'dusiť',
  'jasle',
  'náhly',
  'zubáč',
  'štvrť',
  'krycí',
  'hádač',
  'kánon',
  'obťah',
  'viťúz',
  'mrcha',
  'vymyť',
  'bežať',
  'finta',
  'pokrm',
  'opuka',
  'pampa',
  'zmena',
  'spolu',
  'dolet',
  'gajdy',
  'areál',
  'vokál',
  'ďalší',
  'clivý',
  'hever',
  'práca',
  'gagot',
  'štiep',
  'ohlas',
  'plnka',
  'judáš',
  'logať',
  'rotor',
  'valný',
  'návyk',
  'nieto',
  'špata',
  'pozde',
  'cukor',
  'edikt',
  'tíško',
  'burič',
  'muška',
  'parom',
  'swing',
  'meniť',
  'šteňa',
  'lanýž',
  'výhra',
  'atlas',
  'aktér',
  'drmať',
  'obšiť',
  'herec',
  'dumka',
  'zašiť',
  'palác',
  'cipka',
  'satén',
  'hačať',
  'zubár',
  'zdrap',
  'osiať',
  'atašé',
  'spray',
  'froté',
  'spása',
  'sliva',
  'úklad',
  'druzg',
  'čipka',
  'kolík',
  'tulák',
  'kapún',
  'pašák',
  'jedák',
  'hlina',
  'papáč',
  'nežný',
  'index',
  'vlaha',
  'hubár',
  'sufix',
  'kúkoľ',
  'marec',
  'radar',
  'nárok',
  'pútko',
  'cukrú',
  'psina',
  'načas',
  'háčko',
  'názov',
  'držať',
  'deliť',
  'bordó',
  'ihneď',
  'dôvod',
  'špára',
  'derby',
  'horák',
  'garáž',
  'vojsť',
  'konať',
  'bíreš',
  'dĺžeň',
  'ženba',
  'hudba',
  'vadiť',
  'meliť',
  'štras',
  'myrha',
  'terén',
  'siaty',
  'oblet',
  'naviť',
  'čeriť',
  'fľaša',
  'nutný',
  'manéž',
  'panva',
  'blato',
  'ožran',
  'diéta',
  'ihlan',
  'brloh',
  'preto',
  'smalt',
  'kópia',
  'fidli',
  'revír',
  'lymfa',
  'bojar',
  'zošiť',
  'veslo',
  'vpred',
  'pasta',
  'hnida',
  'šatka',
  'kosiť',
  'dymiť',
  'bokom',
  'líška',
  'potaš',
  'habať',
  'číhať',
  'strom',
  'vlniť',
  'vlasť',
  'rúcho',
  'učený',
  'zaťať',
  'nález',
  'kajka',
  'chlap',
  'súkať',
  'pilot',
  'lovec',
  'tíšiť',
  'domec',
  'nálev',
  'odroň',
  'pobiť',
  'sedan',
  'marka',
  'kuvik',
  'vanúť',
  'sŕkať',
  'ofera',
  'vesta',
  'vstať',
  'odušu',
  'skala',
  'dopyt',
  'skica',
  'štrng',
  'aréna',
  'ôsmak',
  'lalot',
  'brána',
  'divný',
  'túžiť',
  'kutňa',
  'zásyp',
  'vklad',
  'hltať',
  'kódex',
  'úžera',
  'vyňať',
  'mínus',
  'gadžo',
  'načať',
  'kanoe',
  'luhať',
  'rádio',
  'viola',
  'úkrok',
  'céčko',
  'polom',
  'kelím',
  'lutna',
  'sluka',
  'katan',
  'pumpa',
  'opora',
  'budík',
  'utĺcť',
  'šášie',
  'borec',
  'kývať',
  'tenže',
  'trala',
  'srdiť',
  'konto',
  'modul',
  'oltár',
  'madam',
  'lopár',
  'bečať',
  'objem',
  'našiť',
  'tesil',
  'trtáč',
  'hutný',
  'dražé',
  'dukát',
  'vznik',
  'rušeň',
  'hrnúť',
  'hudry',
  'štváč',
  'dereš',
  'tamto',
  'pusta',
  'súlož',
  'ťažiť',
  'kefár',
  'homár',
  'rebro',
  'vývrt',
  'basta',
  'ďalej',
  'plast',
  'trnka',
  'delta',
  'barak',
  'kasňa',
  'dodať',
  'kováč',
  'celta',
  'finiš',
  'tobôž',
  'úsvit',
  'otĺcť',
  'driek',
  'masér',
  'mŕtvy',
  'srnec',
  'behúň',
  'rodiť',
  'metér',
  'simka',
  'sifón',
  'idióm',
  'kašeľ',
  'jaseň',
  'mixáž',
  'čadiť',
  'klaun',
  'pršať',
  'oblak',
  'oplan',
  'pásmo',
  'porno',
  'larva',
  'nieby',
  'hasiť',
  'žitie',
  'kanúť',
  'kvázi',
  'légia',
  'kajak',
  'báseň',
  'sálať',
  'hĺbať',
  'coach',
  'tepať',
  'tenký',
  'kŕmiť',
  'žiara',
  'bivak',
  'rojko',
  'freón',
  'hurma',
  'banán',
  'merný',
  'lomiť',
  'cupot',
  'sonda',
  'sínus',
  'česáč',
  'poste',
  'bagáž',
  'crčať',
  'šturm',
  'lízať',
  'karta',
  'barón',
  'močka',
  'luhár',
  'lampa',
  'pípať',
  'skusy',
  'baran',
  'motať',
  'ryska',
  'beťah',
  'kárať',
  'kúdol',
  'náraz',
  'mámiť',
  'tlupa',
  'nápor',
  'odhad',
  'zrada',
  'betón',
  'barla',
  'diera',
  'lživý',
  'háčik',
  'rival',
  'výmoľ',
  'sečný',
  'horor',
  'céčka',
  'nečin',
  'tárač',
  'zaduť',
  'úľuba',
  'popod',
  'sedma',
  'klada',
  'ekzém',
  'temný',
  'kesón',
  'pojem',
  'pyrit',
  'hrtan',
  'zhluk',
  'tchor',
  'pohan',
  'hmota',
  'reťaz',
  'vraný',
  'koral',
  'úškrn',
  'kojiť',
  'túžba',
  'glacé',
  'dohra',
  'pomer',
  'skejt',
  'dotaz',
  'biľag',
  'orgie',
  'dôkaz',
  'látať',
  'plieť',
  'rezba',
  'vyžiť',
  'mávať',
  'gamba',
  'sovča',
  'sykať',
  'nárys',
  'kokus',
  'závin',
  'šupom',
  'struk',
  'vážka',
  'dumať',
  'vyžať',
  'páriť',
  'neraz',
  'kyslý',
  'srieň',
  'cisár',
  'oviať',
  'mariť',
  'limba',
  'občan',
  'fánka',
  'glosa',
  'obrok',
  'holič',
  'minca',
  'ctený',
  'výzor',
  'búvať',
  'štráf',
  'plece',
  'trend',
  'hrana',
  'kyvot',
  'weber',
  'varuj',
  'koláč',
  'jarka',
  'crkot',
  'nával',
  'ďubať',
  'grman',
  'veliť',
  'hádam',
  'drevo',
  'hnací',
  'pozor',
  'bočka',
  'mesto',
  'solič',
  'zaviť',
  'zažať',
  'dopiť',
  'siláž',
  'dotyk',
  'trecí',
  'kôpor',
  'viniť',
  'fízel',
  'rubár',
  'bodaj',
  'sypký',
  'výron',
  'opitý',
  'kamea',
  'datľa',
  'ťahač',
  'náter',
  'belák',
  'firma',
  'záhyb',
  'petit',
  'cápať',
  'slipy',
  'výnos',
  'vývoz',
  'krysa',
  'háveď',
  'nomád',
  'desiť',
  'oliva',
  'zdrať',
  'gániť',
  'ha-ha',
  'glanc',
  'slnko',
  'čepeľ',
  'klzný',
  'kurič',
  'výčap',
  'kumšt',
  'bočiť',
  'céder',
  'čapiť',
  'vrava',
  'tŕnie',
  'tupiť',
  'obzor',
  'ramár',
  'mučiť',
  'roháč',
  'tisíc',
  'plech',
  'piecť',
  'mýliť',
  'myrta',
  'honec',
  'ľavák',
  'merať',
  'naoko',
  'utkať',
  'tretí',
  'páliť',
  'gibon',
  'modla',
  'veľký',
  'ľahký',
  'pazúr',
  'cesto',
  'kanva',
  'smieť',
  'zádrh',
  'cúvať',
  'obrna',
  'zhuba',
  'cediť',
  'bôžik',
  'stĺcť',
  'visáž',
  'remeň',
  'sýpka',
  'kujný',
  'dobiť',
  'stres',
  'sebec',
  'zrast',
  'zábeh',
  'raziť',
  'skaza',
  'holáň',
  'rímsa',
  'vypiť',
  'dlbať',
  'dcéra',
  'mydlo',
  'dymák',
  'vodka',
  'kozol',
  'pľuha',
  'mafia',
  'vápno',
  'šarha',
  'čípok',
  'jeden',
  'skade',
  'vajce',
  'výdrž',
  'turné',
  'motúz',
  'splín',
  'oštep',
  'tútor',
  'mrdať',
  'idyla',
  'deľba',
  'fóbia',
  'keďže',
  'vŕtať',
  'bukas',
  'posol',
  'koráb',
  'takto',
  'vydaj',
  'nôtiť',
  'rušný',
  'fičúr',
  'heslo',
  'pohár',
  'cysta',
  'ústie',
  'husle',
  'žíhať',
  'hojný',
  'lišaj',
  'mešec',
  'próza',
  'pútec',
  'fifik',
  'núdza',
  'démon',
  'satyr',
  'cieňa',
  'bunda',
  'jarmo',
  'horda',
  'toast',
  'hajať',
  'fujak',
  'šibač',
  'ježiť',
  'zupák',
  'zobuť',
  'rajón',
  'jasať',
  'súšie',
  'láger',
  'honba',
  'sklep',
  'kólia',
  'hobeľ',
  'bacha',
  'obvod',
  'bravo',
  'črvoč',
  'murín',
  'voľba',
  'sútok',
  'rukáv',
  'dojiť',
  'piket',
  'vnada',
  'farma',
  'šitie',
  'závod',
  'vzpor',
  'vecko',
  'živiť',
  'hĺbka',
  'kolos',
  'pitka',
  'krach',
  'múdry',
  'mixér',
  'igric',
  'dojný',
  'pasus',
  'pavúk',
  'súkno',
  'avšak',
  'tlmiť',
  'revať',
  'šťuka',
  'odkop',
  'starý',
  'chlór',
  'bojko',
  'džber',
  'zaryť',
  'bacil',
  'hlúpy',
  'margo',
  'chova',
  'pútač',
  'vysať',
  'lieno',
  'zamat',
  'jarec',
  'výbeh',
  'bažiť',
  'tichý',
  'zatým',
  'vývod',
  'pečať',
  'dojka',
  'zlotý',
  'trara',
  'dunčo',
  'okres',
  'tiket',
  'tonus',
  'variť',
  'sloha',
  'cieva',
  'výkal',
  'hrkať',
  'bridž',
  'motel',
  'varák',
  'počať',
  'galop',
  'súžba',
  'zobák',
  'haniť',
  'tárať',
  'pompa',
  'húfom',
  'cicať',
  'vziať',
  'humno',
  'miasť',
  'žolík',
  'zebra',
  'modus',
  'eidam',
  'matný',
  'hnilý',
  'chata',
  'lomný',
  'zdola',
  'ženáč',
  'kukla',
  'župan',
  'byľku',
  'helma',
  'tréma',
  'laxný',
  'tabak',
  'stade',
  'kŕdeľ',
  'fagan',
  'zajať',
  'sekáč',
  'vášeň',
  'lazár',
  'robot',
  'myseľ',
  'česať',
  'sabat',
  'rodeo',
  'latex',
  'metál',
  'paroh',
  'chrum',
  'brejk',
  'nudiť',
  'dúška',
  'búriť',
  'šport',
  'babra',
  'opuch',
  'psíča',
  'hravý',
  'hobby',
  'vazal',
  'radok',
  'kačka',
  'tvrdý',
  'astma',
  'páter',
  'kadiť',
  'plást',
  'kobka',
  'tečna',
  'argot',
  'tokať',
  'hajde',
  'fúria',
  'anión',
  'obraz',
  'fetiš',
  'hapčí',
  'farár',
  'vyjsť',
  'fixír',
  'rýpať',
  'vlahý',
  'saldo',
  'odpor',
  'berný',
  'rapot',
  'osika',
  'rabín',
  'oddať',
  'kužeľ',
  'vodiť',
  'ležmo',
  'aspik',
  'bedrá',
  'darca',
  'vagón',
  'largo',
  'súdiť',
  'zinok',
  'akosť',
  'kauza',
  'mánia',
  'skorý',
  'cikať',
  'sľuda',
  'morka',
  'astra',
  'opľuť',
  'panák',
  'zámka',
  'kolár',
  'trast',
  'vidly',
  'lichý',
  'návod',
  'zúfať',
  'loviť',
  'farba',
  'ražné',
  'popis',
  'korma',
  'súlad',
  'lepok',
  'kytka',
  'posyp',
  'hrmot',
  'škrob',
  'valiť',
  'rybár',
  'bodrý',
  'punkt',
  'tužiť',
  'dakde',
  'nálet',
  'ložný',
  'piano',
  'notár',
  'etika',
  'kydať',
  'ražeň',
  'ustať',
  'výkop',
  'zaňho',
  'vľavo',
  'slziť',
  'očitý',
  'obnos',
  'baník',
  'grcať',
  'úbohý',
  'dojať',
  'kúzlo',
  'polyp',
  'ďobať',
  'blues',
  'kutil',
  'bežka',
  'tenis',
  'lokňa',
  'párať',
  'trieť',
  'kader',
  'zámik',
  'nóvum',
  'islam',
  'rolka',
  'topoľ',
  'slovo',
  'úmera',
  'mecén',
  'pytač',
  'točka',
  'kľuka',
  'modem',
  'čereň',
  'papek',
  'hrdlo',
  'poker',
  'núrať',
  'boháč',
  'najať',
  'mango',
  'sáčik',
  'totem',
  'hodiť',
  'čoraz',
  'kupec',
  'lakeť',
  'menáž',
  'hertz',
  'žezlo',
  'zvoľa',
  'občas',
  'činný',
  'mláto',
  'psota',
  'okrem',
  'rámus',
  'ískať',
  'lávka',
  'fúkač',
  'cudný',
  'sušič',
  'krimi',
  'dáviť',
  'tumor',
  'horný',
  'teraz',
  'frkot',
  'vyťať',
  'pivár',
  'tmavý',
  'tyran',
  'hvizd',
  'oveľa',
  'šalát',
  'ladom',
  'sisal',
  'zorný',
  'menič',
  'ľapot',
  'čatár',
  'valec',
  'koláž',
  'klzák',
  'tikot',
  'popiť',
  'ceriť',
  'tesať',
  'nabok',
  'sunúť',
  'buket',
  'dlaba',
  'odbiť',
  'fakír',
  'židák',
  'maska',
  'móres',
  'rubín',
  'žatva',
  'duriť',
  'hnedý',
  'garde',
  'dlaha',
  'častý',
  'vojna',
  'peruť',
  'začas',
  'mylný',
  'lemeš',
  'zajac',
  'letáč',
  'číriť',
  'silný',
  'stesk',
  'váľok',
  'rudka',
  'obrva',
  'ňufák',
  'úvaha',
  'onuca',
  'výkrm',
  'kakao',
  'ťapot',
  'metať',
  'vpich',
  'ráčiť',
  'letún',
  'dožuť',
  'dátum',
  'novic',
  'bežný',
  'rúčka',
  'úvrat',
  'vŕzať',
  'míňať',
  'vedno',
  'krveľ',
  'psica',
  'zjazd',
  'jedlý',
  'rande',
  'šišak',
  'súsek',
  'budiť',
  'somár',
  'drahý',
  'ocliť',
  'uctiť',
  'šálka',
  'nasať',
  'špión',
  'akosi',
  'škola',
  'chňap',
  'dogma',
  'lepiť',
  'vrelý',
  'vojak',
  'odsun',
  'volič',
  'hájiť',
  'hybaj',
  'dvere',
  'súrny',
  'kopov',
  'šunka',
  'karát',
  'kočka',
  'zorať',
  'hynúť',
  'trial',
  'lapiť',
  'chlad',
  'merač',
  'šábes',
  'debil',
  'nárek',
  'samit',
  'dióda',
  'parák',
  'motýľ',
  'ulita',
  'krutý',
  'čákov',
  'tango',
  'dueto',
  'verný',
  'pizza',
  'škára',
  'lokaj',
  'slama',
  'vyšiť',
  'výmer',
  'labuť',
  'zrásť',
  'akýsi',
  'scéna',
  'fráza',
  'bazén',
  'prsia',
  'rezký',
  'blahý',
  'hrčať',
  'spavý',
  'vozič',
  'lepič',
  'cúdiť',
  'hlava',
  'cudzí',
  'zvada',
  'faloš',
  'fíčer',
  'náruč',
  'jazda',
  'ústav',
  'nekov',
  'habán',
  'súper',
  'bubon',
  'várka',
  'penny',
  'ópium',
  'ohmat',
  'imidž',
  'zosuv',
  'bobok',
  'ležať',
  'čakať',
  'vrieť',
  'tlmič',
  'odraz',
  'jidiš',
  'robiť',
  'bodať',
  'terno',
  'spásť',
  'kázeň',
  'hálka',
  'letec',
  'ľudia',
  'javiť',
  'poryv',
  'vrták',
  'šabľa',
  'ultra',
  'večer',
  'holiť',
  'imelo',
  'lapka',
  'názor',
  'varta',
  'výlov',
  'padlý',
  'trest',
  'túliť',
  'futro',
  'kamsi',
  'tovar',
  'hasič',
  'kasta',
  'dohán',
  'mačka',
  'papať',
  'murár',
  'kuruc',
  'náhle',
  'dipól',
  'húfny',
  'gágor',
  'misia',
  'fikať',
  'prach',
  'nadol',
  'dojič',
  'sekať',
  'podeň',
  'chorý',
  'rožný',
  'ľpieť',
  'ničiť',
  'tykať',
  'mlieť',
  'aleja',
  'varný',
  'hrací',
  'šuška',
  'tiara',
  'výboj',
  'kaziť',
  'spara',
  'selén',
  'stráň',
  'stopa',
  'lepra',
  'pučiť',
  'zákon',
  'niečí',
  'nahor',
  'včera',
  'pátos',
  'fešný',
  'omega',
  'rosiť',
  'penka',
  'koráľ',
  'pavúz',
  'sušiť',
  'riasa',
  'adieu',
  'bahno',
  'drina',
  'stráž',
  'šanta',
  'toxín',
  'holeň',
  'obrad',
  'veľmi',
  'estét',
  'poéma',
  'gejša',
  'šibák',
  'akcia',
  'pigať',
  'guráž',
  'lokál',
  'pomoc',
  'tučko',
  'pustý',
  'knuta',
  'bučať',
  'držky',
  'kufor',
  'odžiť',
  'sťaby',
  'roniť',
  'ľúbiť',
  'haluz',
  'mušľa',
  'inšie',
  'fučať',
  'bisťu',
  'vyžla',
  'rytec',
  'úkryt',
  'peháň',
  'ťažký',
  'proti',
  'ohrev',
  'výška',
  'šibať',
  'krúpa',
  'slina',
  'harfa',
  'piaty',
  'verva',
  'objať',
  'blaho',
  'motív',
  'ošiaľ',
  'mozoľ',
  'laser',
  'zákop',
  'fénix',
  'rožok',
  'kisňa',
  'hltač',
  'zámer',
  'salva',
  'mumps',
  'štyri',
  'moták',
  'summa',
  'odval',
  'súbor',
  'malta',
  'pásak',
  'sítie',
  'lámať',
  'rúbaň',
  'lajda',
  'ajhľa',
  'vačka',
  'mreža',
  'upnúť',
  'sinka',
  'sotiť',
  'kovať',
  'žúžoľ',
  'maggi',
  'tranz',
  'usnúť',
  'dediť',
  'ladič',
  'bdelý',
  'dopad',
  'waltz',
  'poliť',
  'hebký',
  'trhan',
  'dežma',
  'ráňať',
  'štica',
  'déčka',
  'zdvih',
  'kečka',
  'jurta',
  'obsah',
  'špurt',
  'hámor',
  'temer',
  'skoba',
  'titul',
  'tromf',
  'farad',
  'lazúr',
  'prova',
  'trpný',
  'lotor',
  'dobre',
  'zámok',
  'sihoť',
  'obrus',
  'notes',
  'zeler',
  'pokyn',
  'mrzák',
  'mašľa',
  'úšust',
  'dobré',
  'bádať',
  'otčim',
  'krava',
  'neduh',
  'hokej',
  'tasiť',
  'určiť',
  'výpar',
  'čnieť',
  'repík',
  'zával',
  'náhon',
  'pijak',
  'moriť',
  'sršať',
  'zlato',
  'pánča',
  'šifra',
  'spieť',
  'spleť',
  'obava',
  'výkaz',
  'odoka',
  'bizón',
  'bitka',
  'úskok',
  'prask',
  'vykať',
  'rôsol',
  'šatňa',
  'posed',
  'rubeľ',
  'sprvu',
  'zápas',
  'nafta',
  'čačka',
  'žľaza',
  'bager',
  'bábka',
  'anjel',
  'obmyť',
  'morča',
  'kombi',
  'jakot',
  'trakt',
  'kožák',
  'šúľok',
  'nabiť',
  'jadro',
  'vlani',
  'šmrnc',
  'octan',
  'stred',
  'maľba',
  'srdce',
  'rušiť',
  'metla',
  'snivý',
  'varič',
  'drieň',
  'zeleň',
  'oceán',
  'buľva',
  'honiť',
  'vedľa',
  'kúpať',
  'silon',
  'potme',
  'mihať',
  'vešať',
  'husár',
  'streh',
  'velúr',
  'peleš',
  'pocit',
  'zhrať',
  'banda',
  'teror',
  'archa',
  'kúdeľ',
  'šepmo',
  'dúšok',
  'regál',
  'tanec',
  'hraný',
  'odlet',
  'dielo',
  'obora',
  'socha',
  'strih',
  'fakľa',
  'aféra',
  'dĺžka',
  'chvat',
  'legát',
  'kopať',
  'mláka',
  'tresk',
  'silúr',
  'ponad',
  'vejár',
  'rihať',
  'aziat',
  'bôľny',
  'kazár',
  'neger',
  'herňa',
  'vatra',
  'bujak',
  'kompa',
  'pipka',
  'tešiť',
  'nápis',
  'manko',
  'sopka',
  'závit',
  'odpiť',
  'múmia',
  'sodný',
  'znova',
  'rodný',
  'zmôcť',
  'grgať',
  'úsmev',
  'sojka',
  'jedlo',
  'bodec',
  'čapta',
  'hrdza',
  'junák',
  'twist',
  'odľud',
  'vývin',
  'obrať',
  'géčko',
  'šerpa',
  'bazár',
  'krhla',
  'právo',
  'závoz',
  'fúkať',
  'škála',
  'masáž',
  'breza',
  'črpák',
  'vchod',
  'dojča',
  'dúfať',
  'pinka',
  'fádny',
  'vrčať',
  'odísť',
  'komik',
  'audit',
  'kúpeľ',
  'bázeň',
  'fixka',
  'zmija',
  'sviňa',
  'výsyp',
  'piest',
  'čariť',
  'lebka',
  'bohém',
  'ucháň',
  'vztyk',
  'topás',
  'zásah',
  'páper',
  'vyzuť',
  'hriať',
  'jačať',
  'mazák',
  'mihom',
  'šibký',
  'ženiť',
  'talón',
  'čerta',
  'bašta',
  'šušot',
  'šmyks',
  'odňať',
  'pevný',
  'žieňa',
  'závej',
  'nápad',
  'schod',
  'ďateľ',
  'réžia',
  'iskra',
  'fígeľ',
  'ľuľok',
  'záber',
  'fluór',
  'nikde',
  'padať',
  'oblok',
  'tubus',
  'javor',
  'chúďa',
  'živáň',
  'zhoda',
  'sever',
  'lejak',
  'beláň',
  'lišta',
  'pípeť',
  'mokka',
  'konča',
  'behať',
  'núkať',
  'rasca',
  'pchať',
  'tajga',
  'pohon',
  'národ',
  'záloh',
  'tirák',
  'tlieť',
  'chasa',
  'béčko',
  'zadok',
  'tlecí',
  'droby',
  'párok',
  'liaty',
  'hubiť',
  'krčma',
  'opera',
  'mihot',
  'kábel',
  'semiš',
  'tuleň',
  'rušať',
  'vžitý',
  'pajác',
  'pukať',
  'súriť',
  'ťukať',
  'páska',
  'gazík',
  'lievč',
  'ladiť',
  'pýcha',
  'vodič',
  'oktán',
  'vplyv',
  'vŕkať',
  'zátrh',
  'tanúť',
  'zožať',
  'osvit',
  'humor',
  'priať',
  'rýdzi',
  'vôbec',
  'šakal',
  'šuhaj',
  'rušič',
  'podať',
  'tasať',
  'hovno',
  'volej',
  'voliť',
  'koreň',
  'rydlo',
  'šnaps',
  'mletý',
  'ragby',
  'chren',
  'kobra',
  'čítať',
  'rároh',
  'pižmo',
  'vábiť',
  'kupón',
  'matka',
  'výtok',
  'zrelý',
  'lôžko',
  'pútať',
  'skúpy',
  'žinka',
  'hotel',
  'vinúť',
  'zápač',
  'fotón',
  'sánky',
  'padák',
  'lúmen',
  'šelma',
  'prečo',
  'brest',
  'osteň',
  'voľno',
  'vinič',
  'ricín',
  'pápež',
  'hrada',
  'prísť',
  'špieť',
  'želať',
  'nazad',
  'bomba',
  'fotel',
  'dráha',
  'štuka',
  'misál',
  'činka',
  'často',
  'zašlý',
  'zápor',
  'kutiť',
  'hodný',
  'dupot',
  'márny',
  'fenol',
  'strop',
  'bibas',
  'až-až',
  'pínia',
  'vačok',
  'nábor',
  'chlop',
  'droga',
  'mužík',
  'baťko',
  'líčiť',
  'peceň',
  'pokál',
  'ktorý',
  'dôjsť',
  'nádrž',
  'letok',
  'tôniť',
  'ryčať',
  'míner',
  'sonet',
  'tápať',
  'radca',
  'tráva',
  'zdroj',
  'belko',
  'kaňon',
  'poťah',
  'vlhký',
  'lapák',
  'samec',
  'písmo',
  'výčin',
  'libra',
  'kŕkať',
  'špina',
  'obrat',
  'výlev',
  'opona',
  'goral',
  'číslo',
  'sudca',
  'trnúť',
  'ukryť',
  'sopľa',
  'tonik',
  'ohnúť',
  'honor',
  'ručiť',
  'mohár',
  'nájsť',
  'slieň',
  'došlý',
  'pozér',
  'úvrať',
  'apríl',
  'možno',
  'slasť',
  'močiť',
  'odkaz',
  'ohava',
  'rázga',
  'peniť',
  'dojem',
  'lotos',
  'spúšť',
  'rigol',
  'obeta',
  'hádka',
  'autor',
  'sipot',
  'válov',
  'maces',
  'džbán',
  'zapiť',
  'mamut',
  'hyena',
  'tešla',
  'letmý',
  'vinea',
  'dvoje',
  'aspoň'
];
